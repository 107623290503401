import EditDate from "../components/EditDate"
import { deCamelize, getLastUpdated, slugify } from "../functions/utils"
import Arrivals from "./Arrivals"
import FreeText from "./FreeText"
import Inclusions from "./Inclusions"

const ArrivalInformation = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    const isDeparture = slug.startsWith('departure')
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">{deCamelize(slug)}</h2>
                <EditDate date={date} />
            </div>
            {data.map((info, i) => {
                if (!info || !info.fields) return
                const { arrivalInformation, arrivals, inclusions, additionalInfo } = info.fields
                return (
                    <div id={slugify(arrivalInformation)} className="block-style" key={i}>
                        {arrivalInformation && <h3 className="content_h3">{arrivalInformation}</h3>}
                        {arrivals && <Arrivals data={arrivals} slug={isDeparture ? 'departures' : 'arrivals'} isDeparture={isDeparture} />}
                        {inclusions && <Inclusions data={inclusions} slug='inclusions' />}
                        {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
                    </div>
                )
            })}
        </article>
    )
}

export default ArrivalInformation