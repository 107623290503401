import EditDate from "../components/EditDate"
import InteractiveMap from "../components/InteractiveMap"
import { getLastUpdated } from "../functions/utils"

const Location = ({ data, hideTitle }) => {
    const { internalName, locationType2, location, phoneNumber, website, timeDistanceToAirport, timeDistanceToDock, timeDistanceToHotel, timeDistanceToHospitalityRoom } = data.fields
    const date = getLastUpdated(data)
    return (
        <div style={{marginTop: 20}}>
            <div className="flex-between-centered">
                {!hideTitle && <b className="content_h4" style={{marginBottom: 10}}>{internalName}</b>}
                {!hideTitle && <EditDate date={date} />}
            </div>
            {locationType2 && (
                <div style={{marginBottom: 20}}>
                    <b>Features</b>
                    <ul className="list-style" style={{marginTop: 10}}>
                        {locationType2.map(v => <li key={v}>{v}</li>)}
                    </ul>
                </div>
            )}
            {location && <InteractiveMap lng={location.lon} lat={location.lat} />}
            {phoneNumber && <p><b>Phone Number:</b> <a href={`tel:${phoneNumber}`}>{phoneNumber}</a></p>}
            {website && (
                <div className='d-flex-align-center' style={{marginTop: 20}}>
                    <b>Website:</b>
                    <a className='link-wrap' href={website} target='_blank'>{website}</a>
                </div>
            )}
            {timeDistanceToAirport && (
                <div style={{marginTop: 20}}>
                    <b>Time & Distance to Airport</b>
                    <p>{timeDistanceToAirport}</p>
                </div>
            )}
            {timeDistanceToDock && (
                <div style={{marginTop: 20}}>
                    <b>Time & Distance to Dock</b>
                    <p>{timeDistanceToDock}</p>
                </div>
            )}
            {timeDistanceToHotel && (
                <div style={{marginTop: 20}}>
                    <b>Time & Distance to Hotel</b>
                    <p>{timeDistanceToHotel}</p>
                </div>
            )}
            {timeDistanceToHospitalityRoom && (
                <div style={{marginTop: 20}}>
                    <b>Time & Distance to Hospitality Room</b>
                    <p>{timeDistanceToHospitalityRoom}</p>
                </div>
            )}
        </div>
    )
}

export default Location
