import EditDate from "../components/EditDate"
import InteractiveMap from "../components/InteractiveMap"
import { getLastUpdated } from "../functions/utils"
import FreeText from "./FreeText"

const DockInformation = ({ data }) => {
    const { portDock, Dock, timeDistanceToAirport, timeDistanceToHotel, timeDistanceToHospitalityRoom, additionalInfo } = data.fields
    const date = getLastUpdated(data)
    return (
        <div>
            <div className="flex-between-centered">
                <b className="content_h4" style={{marginBottom: 15}}>{portDock}</b>
                <EditDate date={date} />
            </div>
            {Dock && <InteractiveMap lng={Dock.lon} lat={Dock.lat} />}
            {timeDistanceToAirport && (
                <div style={{marginTop: 20}}>
                    <b>Time & Distance to Airport</b>
                    <p>{timeDistanceToAirport}</p>
                </div>
            )}
            {timeDistanceToHotel && (
                <div style={{marginTop: 20}}>
                    <b>Time & Distance to Hotel</b>
                    <p>{timeDistanceToHotel}</p>
                </div>
            )}
            {timeDistanceToHospitalityRoom && (
                <div style={{marginTop: 20}}>
                    <b>Time & Distance to Hospitality Room</b>
                    <p>{timeDistanceToHospitalityRoom}</p>
                </div>
            )}
            {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
        </div>
    )
}

export default DockInformation