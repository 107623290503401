import { deCamelize, getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"

const Ship = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <h2 className="content_h2">{deCamelize(slug)}</h2>
            <EditDate date={date} />
            {data.map((ship, i) => {
                if (!ship || !ship.fields) return
                const { name, Overview: link } = ship.fields
                return (
                    <div className="block-style" key={i}>
                        <h4 className="content_h4">{name}</h4>
                        <p className="mb-0"><b>Ship info:</b> <a target="_blank" href={link}>{link}</a></p>
                    </div>
                )
            })}
        </article>
    )
}

export default Ship
