export const slugify = (str) => {
    if (!str) return
    return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
}

export const getRichContentStringForEmbed = (rich) => {
    return rich.content[0].content[0].value
}

export const getIframeSrcFromString = (str) => {
    const text = str.split(new RegExp('src="', 'i'))
    if (!text || !text[1]) return
    return text[1].split('"')[0]
}

export const isUpperCase = (str) => {
    return /^[A-Z]*$/.test(str)
}

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return ""
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export const deCamelize = (text) => {
  if (isUpperCase(text)) return text
  if (text.length < 3) return text
  const result = text.replace(/([A-Z])/g, " $1")
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const dateStringToUSDate = (updatedAt) => {
    const options = { year: '2-digit', month: 'short', day: 'numeric', hour12: false, hour: 'numeric', minute: 'numeric' }
    const updatedAtDate = new Date(updatedAt)
    if (updatedAtDate == 'Invalid Date') return
    const date1 = new Date(updatedAtDate.getTime() + updatedAtDate.getTimezoneOffset() * 60000).toLocaleDateString('en-US', options)
    const date2 = date1.split(' ')
    return `${date2[1].replace(/,$/,'-')}${date2[0]}-${date2[2].replace(/,$/,'')}, ${date2[3]} (UTC)`
}

export const getLastUpdated = (updatedAt, items) => {
    if (!updatedAt && !items) return
    const latest = updatedAt && !items ? updatedAt : items.reduce((max, item) => max.sys.updatedAt > item.sys.updatedAt ? max : item)
    return dateStringToUSDate(latest.sys.updatedAt)
}

export const fetchFromExpeditions = async (key, contentType) => {
  const url = `https://cdn.contentful.com/spaces/${process.env.CONTENTFUL_EXPEDITIONS_SPACE}/environments/master/entries?access_token=${process.env.CONTENTFUL_EXPEDITIONS_DELIVERY_TOKEN}&content_type=${contentType}&sys.id=${key}&include=1`
  try {
    const json = await fetch(url)
    const data = await json.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

export const proccessAlgoliaHits = (item) => {
    const hits = []
    const highlightMetadata = item._highlightResult.metadata || { tags: [] }
    highlightMetadata.tags.forEach(m => {
        const mached = m.name.matchedWords
        if (mached && mached.length) {
            hits.push({
                name: m.name,
                _highlightResult: { name: m.name },
                breadcrumbs: [item.fields.name],
            })
        }
    })
    if (item._highlightResult.fields) {
        for (const [key, value] of Object.entries(item._highlightResult.fields)) {
            if (typeof value === 'object' && !Array.isArray(value) && value !== null ) {
                const mached = value.matchedWords
                if (mached && mached.length && mached[0].length > 2) {
                    hits.push({
                        name: value,
                        _highlightResult: { name: value },
                        ...(key !== 'name' && { anchor: key }),
                        breadcrumbs: key !== 'name' ? [item.fields.name, deCamelize(key)] : [item.fields.name],
                        tags: highlightMetadata.tags,
                    })
                }
                if (value.metadata) {
                    const metadataMatch = value.metadata.fieldName
                    if (metadataMatch.matchedWords && metadataMatch.matchedWords.length) {
                        hits.push({
                            name: value.metadata.fieldName,
                            _highlightResult: { name: value.metadata.fieldName },
                            breadcrumbs: [item.fields.name, deCamelize(key)],
                            anchor: key,
                            tags: highlightMetadata.tags,
                        })
                    }
                }
                if (value.fields) {
                    for (const [nKey, nValue] of Object.entries(value.fields)) {
                        const nMached = nValue.matchedWords
                        if (nMached && nMached.length && nMached[0].length > 2) {
                            //if (nValue.matchLevel === 'full') {
                                hits.push({
                                    name: nValue,
                                    _highlightResult: { name: nValue },
                                    anchor: key,
                                    breadcrumbs: [item.fields.name, deCamelize(key)],
                                    tags: highlightMetadata.tags,
                                })
                            //}
                        }
                        if (nValue.fields) {
                            for (const [nNKey, nNValue] of Object.entries(nValue.fields)) {
                                const nNmached = nNValue.matchedWords
                                if (nNmached && nNmached.length && nNmached[0].length > 2) {
                                    //if (nValue.matchLevel === 'full') {
                                        hits.push({
                                            name: nNValue,
                                            _highlightResult: { name: nNValue },
                                            anchor: key,
                                            breadcrumbs: [item.fields.name, deCamelize(key)],
                                            tags: highlightMetadata.tags,
                                        })
                                    //}
                                }
                            }
                        }
                        if (Array.isArray(nValue)) {
                            nValue.forEach(nv => {
                                if (nv.value) {
                                    const nVVMmached = nv.matchedWords
                                    if (nVVMmached && nVVMmached.length && nVVMmached[0].length > 2) {
                                        hits.push({
                                            name: nv,
                                            _highlightResult: { name: nv },
                                            anchor: key,
                                            breadcrumbs: [item.fields.name, deCamelize(key)],
                                            tags: highlightMetadata.tags,
                                        })
                                    }
                                } else {
                                    for (const [nVkey, nVvalue] of Object.entries(nv.fields)) {
                                        const nVMmached = nVvalue.matchedWords
                                        if (nVMmached && nVMmached.length && nVMmached[0].length > 2) {
                                            hits.push({
                                                name: nVvalue,
                                                _highlightResult: { name: nVvalue },
                                                anchor: key,
                                                breadcrumbs: [item.fields.name, deCamelize(key)],
                                                tags: highlightMetadata.tags,
                                            })
                                        } else if (nVvalue.content) {
                                            const nvRichValue = nVvalue.content[0].content[0].value
                                            if (nvRichValue.matchLevel === 'full') {
                                                hits.push({
                                                    name: nvRichValue,
                                                    _highlightResult: { name: nvRichValue },
                                                    anchor: key,
                                                    breadcrumbs: [item.fields.name, deCamelize(key)],
                                                    tags: highlightMetadata.tags,
                                                })
                                            }
                                        }
                                    }
                                }
                            })
                        }
                        

                    }
                }
                
            } else if (Array.isArray(value)) {//1
                value.forEach(v => {
                    const mached = v.matchedWords
                    if (v.metadata) {
                        const metadataMatch = v.metadata.fieldName
                        if (metadataMatch.matchedWords && metadataMatch.matchedWords.length) {
                            const isArrDuplicate = hits.find(hit => hit.name.value === v.metadata.fieldName.value)
                            if (!isArrDuplicate) {
                                hits.push({
                                    name: v.metadata.fieldName,
                                    _highlightResult: { name: v.metadata.fieldName },
                                    anchor: key,
                                    breadcrumbs: [item.fields.name, deCamelize(key)],
                                    tags: highlightMetadata.tags,
                                })
                            }
                        }
                    }
                    if (mached && mached.length && mached[0].length > 2) {
                        //if (v.matchLevel === 'full') {
                            hits.push({
                                name: v,
                                _highlightResult: { name: v },
                                anchor: key,
                                breadcrumbs: [item.fields.name, deCamelize(key)],
                                tags: highlightMetadata.tags,
                            })
                        //}
                    }
                    if (v.fields) {
                        for (const [nArrKey, nArrValue] of Object.entries(v.fields)) {
                            if (Array.isArray(nArrValue)) {//2
                                nArrValue.forEach((nArrArrValue, i) => {
                                    if (nArrArrValue.metadata) {
                                        const metadataMatch = nArrArrValue.metadata.fieldName
                                        const nestedAnchor = nArrKey + `${i !== 0 ? i : ''}`
                                        if (metadataMatch.matchedWords && metadataMatch.matchedWords.length) {
                                            const isArrDuplicate = hits.find(hit => hit.name.value === nArrArrValue.metadata.fieldName.value)
                                            if (!isArrDuplicate) {
                                                hits.push({
                                                    name: nArrArrValue.metadata.fieldName,
                                                    _highlightResult: { name: nArrArrValue.metadata.fieldName },
                                                    anchor: nestedAnchor,
                                                    breadcrumbs: [item.fields.name, deCamelize(key)],
                                                    tags: highlightMetadata.tags,
                                                })
                                            }
                                        }
                                    }
                                    if (nArrArrValue.value && nArrArrValue.matchedWords && nArrArrValue.matchedWords.length > 1) {
                                        // recheck later#1 console.log(nArrArrValue, 'nArrArrValue')
                                        hits.push({
                                            name: nArrArrValue,
                                            _highlightResult: { name: nArrArrValue },
                                            anchor: key,
                                            breadcrumbs: [item.fields.name, deCamelize(key)],
                                            tags: highlightMetadata.tags,
                                        })
                                    }
                                    if (nArrArrValue.fields) {
                                        for (const [nNArrArrKey, nNArrArrValue] of Object.entries(nArrArrValue.fields)) {
                                            if (Array.isArray(nNArrArrValue)) {//3
                                                nNArrArrValue.forEach(nNArrArrArrValue => {
                                                    const matched = nNArrArrArrValue
                                                    if (matched.value && matched.matchedWords && matched.matchedWords.length) {
                                                        hits.push({
                                                            name: matched,
                                                            _highlightResult: { name: matched },
                                                            anchor: key,
                                                            breadcrumbs: [item.fields.name, deCamelize(key)],
                                                            tags: highlightMetadata.tags,
                                                        })
                                                    }
                                                    if (nNArrArrArrValue.fields) {
                                                        for (const [nNNArrArrKey, nNNArrArrArrValue] of Object.entries(nNArrArrArrValue.fields)) {
                                                            if (Array.isArray(nNNArrArrArrValue)) {//4
                                                                // nNNArrArrArrValue.forEach(nNNArrArrArrArrValue => {
                                                                //     const matched = nNNArrArrArrArrValue
                                                                //     if (matched.value && matched.matchedWords && matched.matchedWords.length) {
                                                                //         hits.push({
                                                                //             name: matched,
                                                                //             _highlightResult: { name: matched },
                                                                //             anchor: key,
                                                                //             breadcrumbs: [item.fields.name, deCamelize(key)],
                                                                //             tags: highlightMetadata.tags,
                                                                //         })
                                                                //     }
                                                                // })
                                                            } else {
                                                                const matched = nNNArrArrArrValue
                                                                if (matched.value && matched.matchedWords && matched.matchedWords.length > 1) {
                                                                    hits.push({
                                                                        name: matched,
                                                                        _highlightResult: { name: matched },
                                                                        anchor: key,
                                                                        breadcrumbs: [item.fields.name, deCamelize(key)],
                                                                        tags: highlightMetadata.tags,
                                                                    })
                                                                }
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                            if (nNArrArrValue.matchedWords && nNArrArrValue.matchLevel === 'full' && nNArrArrValue.matchedWords.length && nNArrArrValue.matchedWords[0].length > 2) {
                                                hits.push({
                                                    name: nNArrArrValue,
                                                    _highlightResult: { name: nNArrArrValue },
                                                    anchor: key,
                                                    breadcrumbs: [item.fields.name, deCamelize(key)],
                                                    tags: highlightMetadata.tags,
                                                })
                                            }
                                        }
                                    }
                                })
                            }
                            const nArrMached = nArrValue.matchedWords
                            if (nArrValue && nArrMached && nArrMached.length && nArrMached[0].length > 2) {
                                if (nArrValue.matchLevel === 'full') {
                                    hits.push({
                                        name: nArrValue,
                                        _highlightResult: { name: nArrValue },
                                        anchor: key,
                                        breadcrumbs: [item.fields.name, deCamelize(key)],
                                        tags: highlightMetadata.tags,
                                    })
                                }
                            } else if (nArrValue.content) {
                                const nArrRichValue = nArrValue.content[0].content[0].value
                                if (nArrRichValue.matchLevel === 'full') {
                                    hits.push({
                                        name: nArrRichValue,
                                        _highlightResult: { name: nArrRichValue },
                                        anchor: key,
                                        breadcrumbs: [item.fields.name, deCamelize(key)],
                                        tags: highlightMetadata.tags,
                                    })
                                }
                            }
                            
                        }
                    }
                })
            }
        }
    }
    return hits
}