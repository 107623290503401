import { autocomplete } from '@algolia/autocomplete-js';
import React, { createElement, Fragment, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client'
import AnchorMenu from './AnchorMenu';
import { isUpperCase } from '../functions/utils';

export function Autocomplete(props) {
  const containerRef = useRef(null);
  const panelRootRef = useRef(null);
  const rootRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      insights: true,
      renderer: { createElement, Fragment, render: () => {} },
      render({ children, state, Fragment, components, render }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = ReactDOM.createRoot(root);
        }
        //panelRootRef.current.render(children);
        
        const { preview, item } = state.context;
         
        const anchorList = []
        if (item) {
          const folder = item.contentType === 'extension' ? '/extensions/' : ''
          for (let [key] of Object.entries(item.fields)) {
            anchorList.push({
              name: isUpperCase(key) ? key : key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
              slug: `${folder}/${item.fields.slug}`,
              id: key
            })
          }
        }

        panelRootRef.current.render(
          <Fragment>
            <div className="aa-Grid">
              <div className="aa-Results aa-Column">{children}</div>
              {preview && (
                <div className="aa-Preview aa-Column aa-Panel--scrollable">
                  <ul className='aa-PreviewTags text-center'>
                    {preview.tags && (
                      preview.tags.map((tag, i) => (
                        <li className='aa-PreviewTags__item' key={i}>{tag.name.value}</li>
                      ))
                    )}
                  </ul>
                  <div className="aa-PreviewTitle text-center">
                    {preview.breadcrumbs && preview.breadcrumbs[0]}
                  </div>
                  <div className="aa-PreviewDescription">
                      <div className="aa-PreviewDescription__content">
                        {preview.anchor && (
                          <components.Highlight hit={preview} attribute="name" />
                        )}
                      </div>
                    {anchorList.length && (
                      <AnchorMenu data={anchorList} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        )
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div ref={containerRef} />;
}
