const Deviations = ({ data }) => {
    return (
        <div className="deviation-block">
            <svg role="presentation" ariaHidden="true" width="40" height="40" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="none" style={{verticalAlign: "middle"}}><path fillRule="evenodd" clipRule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM18.2898 8.31392C18.2898 9.54411 17.2644 10.5414 15.9996 10.5414C14.7347 10.5414 13.7094 9.54411 13.7094 8.31392C13.7094 7.08374 14.7347 6.08647 15.9996 6.08647C17.2644 6.08647 18.2898 7.08374 18.2898 8.31392ZM13.9917 12.2044H18.0073V24.4397H13.9917V12.2044Z" fill="#fff"></path></svg>
            <div>
                Please note that there are one or more departures with a DEVIATION. <br />
                Deviations will be identified in the subject matter headers or titles with the applicable VOYAGE ID.
                <ul className="list-style" style={{marginTop: 10, fontWeight: 700}}>
                    {data.map(v => <li key={v}>DEVIATION OR CHANGE: {v}</li>)}
                </ul>
            </div>
        </div>
    )
}

export default Deviations