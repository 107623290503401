const AnchorMenuItem = ({el, activeAnchor, setActiveAnchor}) => {
  const activeClass = activeAnchor === el.id ? 'anchor_menu_item_active' : ''
  const hasSub = el.subSections && el.subSections.length
  return (
    <li key={el.name} className={`anchor_menu_item ${activeClass} ${hasSub ? 'anchor_menu_item_sub' : ''}`}>
      <a
        onClick={setActiveAnchor ? () => setActiveAnchor(el.id) : undefined} 
        href={`${el.slug || ''}#${el.id}`}
      >
        {el.name}
      </a>
      <ul className='anchor_menu_list'>
        {hasSub ? el.subSections.map((subEl, i) => (
          <AnchorMenuItem key={i} el={subEl} activeAnchor={activeAnchor} setActiveAnchor={setActiveAnchor} />
        )) : null}
      </ul>
    </li>
  )
}

const AnchorMenu = ({data, activeAnchor, setActiveAnchor}) => {
  const skip = ['Code', 'Slug', 'Name', 'Extension Page Link', 'Trip Highlights', 'People Places Things', 'Extension Operating Dates Text']
  return (
      <nav className='anchor_menu'>
        <p className="anchor_menu_title">On this page</p>
        <ul className='anchor_menu_list'>
          {data.map((el, i) => {
            if (!skip.includes(el.name)) {
              if (el.name.includes('Embed')) {
                el.name = el.name.replace(/Embed$/, '')
              }
              return <AnchorMenuItem key={i} el={el} activeAnchor={activeAnchor} setActiveAnchor={setActiveAnchor} />
            }
          })}
        </ul>
      </nav>
    )
  }

export default AnchorMenu