import { deCamelize, getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"
import MedicalInformation from "./MedicalInformation"
import FreeText from "./FreeText"
import PassportsVisas from "./PassportsVisas"

const EntryRequirements = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">{deCamelize(slug)}</h2>
                <EditDate date={date} />
            </div>
            {data.map((requirement, i) => {
                if (!requirement || !requirement.fields) return
                const { medicalInformation, passportsVisas, additionalInfo, passportVisasDisclaimer } = requirement.fields
                return (
                    <div className="block-style" key={i}>
                        {passportVisasDisclaimer && (
                            <div id="passportVisasDisclaimer">
                                <b>Passport & Visas Disclaimer</b>
                                <p>{passportVisasDisclaimer}</p>
                            </div>
                        )}
                        {passportsVisas && <PassportsVisas data={passportsVisas} slug='passportsVisas' />}
                        {medicalInformation && <MedicalInformation data={medicalInformation} slug='medicalInformation' />}
                        {additionalInfo && <FreeText data={additionalInfo} title='additionalInfo' slug='entryRequirementsadditionalInfo' />}
                    </div>
                )
            })}
        </article>
    )
}

export default EntryRequirements
