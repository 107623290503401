
import { deCamelize, getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

const PublicTransportation = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <h3 className="content_h3">{deCamelize(slug)}</h3>
            <EditDate date={date} />
            {data.map((transport, i) => {
                if (!transport || !transport.fields) return
                const { entryTitle, publicTransportation, publicTransportationLink } = transport.fields
                return (
                    <div className="block-style" key={i}>
                        {entryTitle && <h4 className="content_h4">{entryTitle}</h4>}
                        {publicTransportation && (
                            <div>
                                <b>Public Transportation:</b>
                                <div className="list-style" dangerouslySetInnerHTML={{__html: documentToHtmlString(publicTransportation)}} />
                            </div>
                        )}
                        {publicTransportationLink && (
                            <div className='d-flex-align-center' style={{marginTop: 20}}>
                                <b>Public Transportation Link:</b>
                                <a className='link-wrap' href={publicTransportationLink} target='_blank'>{publicTransportationLink}</a>
                            </div>
                        )}
                    </div>
                )
            })}
        </article>
    )
}


export default PublicTransportation
