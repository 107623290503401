import EditDate from "../components/EditDate"
import { getLastUpdated } from "../functions/utils"
import FreeText from "./FreeText"
import MeetingPoints from "./MeetingPoints"
import PublicTransportation from "./PublicTransportation"

const Arrivals = ({ data, slug, isDeparture }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <EditDate date={date} />
            {data.map((info, i) => {
                const { internalName, transferType, arrivalParameters, meetingPoints, transferWindow, publicTransportation, additionalInfo } = info.fields
                return (
                    <div className="block-style" key={i}>
                        {internalName && <h2 className="content_h2" style={{marginBottom: 30}}>{internalName}</h2>}
                        {transferType && (
                            <div style={{marginBottom: 20}}>
                                <b>Transfer Type</b>
                                <ul className="list-style" style={{marginTop: 10}}>
                                    {transferType.map(v => <li key={v}>{v}</li>)}
                                </ul>
                            </div>
                        )}
                        {arrivalParameters && (
                            <div>
                                <b>{isDeparture ? 'Departure' : 'Arrival' } Parameters</b>
                                <p>{arrivalParameters}</p>
                            </div>
                        )}
                        {transferWindow && (
                            <div>
                                <b>Transfer Window</b>
                                <p>{transferWindow}</p>
                            </div>
                        )}
                        {meetingPoints && <MeetingPoints data={meetingPoints} slug={isDeparture ? 'transferPoints' : 'meetingPoints'} /> }
                        {publicTransportation && <PublicTransportation data={publicTransportation} slug='publicTransportation' /> }
                        {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
                    </div>
                )
            })}
        </article>
    )
}

export default Arrivals