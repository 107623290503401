import { useEffect, useState } from "react"
import Logo from "./Logo.js"
import Search from "./Search.js"
import { client } from '../src/App'
import { dateStringToUSDate } from "../functions/utils.js"
import News from "../modules/News.js"
import EditDate from "./EditDate.js"

const Homepage = () => {
    const [ data, setData ] = useState()

    const fetcher = async () => {
        try {
          const entryItems = await client.getEntries({ content_type: 'news', include: 1, limit: 5 })
          setData(entryItems.items)
        } catch (err) {
          console.log(err)
        }
    }
    
    useEffect(() => {
        fetcher()
    }, [])

    return (
        <div className="homepage">
            <Logo id='2' />
            <Search notModal={true} />
            <div>
                <h2 className="content_h2">News</h2>
                {data && data.map((item, i) => (
                    <div className="block-style" key={i}>
                        <h3 className="content_h3" style={{marginTop: 0}}>{item.fields.title}</h3>
                        <EditDate date={dateStringToUSDate(item.sys.updatedAt)} />
                        {item.fields.contentBlock && item.fields.contentBlock.map((block, i) => <News data={block.fields} key={i} />)}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Homepage