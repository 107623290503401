import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

const News = ({ data }) => {
    if (!data) return
    return (
        <div>
            <h4 className="content_h4">{data.title}</h4>
            {data.content ? <div dangerouslySetInnerHTML={{__html: documentToHtmlString(data.content)}} /> : null}
        </div>
    )
}

export default News