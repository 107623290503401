import EditDate from "../components/EditDate"
import { deCamelize, getLastUpdated } from "../functions/utils"

const Faq = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">{deCamelize(slug)}</h2>
                <EditDate date={date} />
            </div>
            {data.map((f, i) => {
                if (!f || !f.fields) return
                const { questionEntryTitle, answer } = f.fields
                return (
                    <div key={i} style={{marginBottom: 15}}>
                        <b>{questionEntryTitle}</b>
                        <div style={{marginTop: 10}}>{answer}</div>
                    </div>
                )
            })}
        </article>
     )
}

export default Faq