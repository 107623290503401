import { useEffect, useState, useMemo } from "react"
import { deCamelize, fetchFromExpeditions, getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"

const Itinerary = ({ data, slug }) => {
    const [ itinerary, setItinerary ] = useState()
    const updatedAt = useMemo(() => {
        if (itinerary) {
            return getLastUpdated(itinerary.items)
        }
    }, [itinerary])

    const fetcher = async () => {
        const itemUrl = data.split('/')
        const itin = await fetchFromExpeditions(itemUrl[itemUrl.length - 1], 'pageExpedition')
        const image = itin.includes.Entry.find(el => el.sys.contentType.sys.id === 'photo')
        setItinerary({
            title: itin.items[0].fields.title,
            image,
            items: itin.items[0],
            slug: itin.items[0].fields.slug
        })
    }

    useEffect(() => {
      fetcher()
    }, [])

    return (
        <article id={slug}>
            <h2 className="content_h2">{deCamelize(slug)}</h2>
            <EditDate date={updatedAt} />
            {itinerary && (
                <a
                    href={`https://www.expeditions.com/expeditions/${itinerary.slug}`}
                    className='block-style highlights__item'
                    target="_blank"
                    style={{display: 'block'}}
                >
                    {itinerary.image && itinerary.image.fields && (
                        <div className='highlights__item--img'>
                            <img src={itinerary.image.fields.asset[0].src} alt={itinerary.image.fields.asset[0].name} />
                        </div>
                    )}
                    <h4 className="content_h4" style={{marginTop: 20, marginBottom: 0}}>{itinerary.title}</h4>
                </a>
            )}
        </article>
     )
}

export default Itinerary