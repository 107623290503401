import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import EditDate from "../components/EditDate"
import { getLastUpdated } from "../functions/utils"
import FreeText from "./FreeText"

const PassportsVisas = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h3 className="content_h3">Passport & Visas</h3>
                <EditDate date={date} />
            </div>
            {data.map((el, i) => {
                if (!el || !el.fields) return
                const { internalName, passportsvisas, governmentWebsites, additionalInfo } = el.fields
                return (
                    <div className="block-style" key={i}>
                        {internalName && <h3 style={{marginTop: 10, marginBottom: 30}} className="content_h3">{internalName}</h3>}
                        {passportsvisas && (
                            <div>
                                <b>Required Documentation</b>
                                <ul className="list-style" style={{marginTop: 10}}>
                                    {passportsvisas.map(v => <li key={v}>{v}</li>)}
                                </ul>
                            </div>
                        )}
                        {governmentWebsites && (
                            <div style={{marginBottom: 20}}>
                                <p><b>Government Resources:</b></p>
                                <div className="list-style" dangerouslySetInnerHTML={{__html: documentToHtmlString(governmentWebsites)}} />
                            </div>
                        )}
                        {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
                    </div>
                )
            })}
        </article>
     )
}

export default PassportsVisas