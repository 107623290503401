import EditDate from "../components/EditDate"
import { getLastUpdated } from "../functions/utils"
import FreeText from "./FreeText"
import Location from "./Location"

const AirportDetailed = ({ data }) => {
    const { airportName, location, airportCode, airportWebsite, timeDistanceToDock, timeDistanceToHotel, timeDistanceToHospitalityRoom, additionalInfo } = data.fields
    const date = getLastUpdated(data)
    return (
        <div style={{marginBottom: 20}}>
            <div className="flex-between-centered">
                <b className="content_h4">{airportName} {airportCode && `(${airportCode})`}</b>
                <EditDate date={date} />
            </div>
            {location && location.map((loc, i) => <Location key={i} hideTitle data={loc} />)}
            {/* {airportWebsite && (
                <div className='d-flex-align-center' style={{marginTop: 35}}>
                    <b>Airport Website:</b>
                    <a className='link-wrap' href={airportWebsite} target='_blank'>{airportWebsite}</a>
                </div>
            )} */}
            {timeDistanceToDock && (
                <div style={{marginTop: 20}}>
                    <b>Time & Distance to Dock</b>
                    <p>{timeDistanceToDock}</p>
                </div>
            )}
            {timeDistanceToHotel && (
                <div style={{marginTop: 20}}>
                    <b>Time & Distance to Hotel</b>
                    <p>{timeDistanceToHotel}</p>
                </div>
            )}
            {timeDistanceToHospitalityRoom && (
                <div style={{marginTop: 20}}>
                    <b>Time & Distance to Hospitality Room</b>
                    <p>{timeDistanceToHospitalityRoom}</p>
                </div>
            )}
            {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
        </div>
    )
}

export default AirportDetailed