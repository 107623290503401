


import { deCamelize, getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

const FreeText = ({ data, slug, title }) => {
    const date = getLastUpdated(null, data)
    if (!data) return null
    return (
        <div id={slug}>
            <div className="flex-between-centered">
                <h3 className="content_h3">{deCamelize(title || slug)}</h3>
                <EditDate date={date} />
            </div>
            {data.map((text, i) => {
                if (!text || !text.fields) return
                const { internalName, additionalNotes } = text.fields
                return (
                    <div className="block-style" key={i}>
                        {internalName && <h3 style={{marginTop: 10, marginBottom: 30}} className="content_h3">{internalName}</h3>}
                        {additionalNotes && <div dangerouslySetInnerHTML={{__html: documentToHtmlString(additionalNotes)}} />}
                    </div>
                )
            })}
        </div>
    )
}

export default FreeText
