import { deCamelize, getLastUpdated } from "../functions/utils"
import EditDate from '../components/EditDate'

const productOwners = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">{deCamelize(slug)}</h2>
                <EditDate date={date} />
            </div>
            {data.map((person, i) => {
                if (!person || !person.fields) return
                const { name } = person.fields
                    return (
                    <div className='block-style' key={i}>
                        {name && <div style={{marginBottom: 10}}><b>Name:</b> {name}</div>}
                    </div>
                )
            })}
        </article>
    )
}

export default productOwners