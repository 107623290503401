import EditDate from "../components/EditDate"
import InteractiveMap from "../components/InteractiveMap"
import { getLastUpdated } from "../functions/utils"

const HotelSimple = ({ data }) => {
    const { internalNameEntryTitle, hotelAddress, hotelLocation } = data.fields
    const date = getLastUpdated(data)
    return (
        <div>
            <div className="flex-between-centered">
                <b className="content_h4" style={{marginBottom: 15}}>{internalNameEntryTitle}</b>
                <EditDate date={date} />
            </div>
            {hotelAddress && (
                <div>
                    <b>Hotel Address:</b>
                    <p>{hotelAddress}</p>
                </div>
            )}
            {hotelLocation && <InteractiveMap lng={hotelLocation.lon} lat={hotelLocation.lat} />}
        </div>
    )
}

export default HotelSimple