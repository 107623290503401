import EditDate from "../components/EditDate"
import { deCamelize, getLastUpdated } from "../functions/utils"
import FreeText from "./FreeText"

const Meals = ({ data, slug }) => {
    const date = getLastUpdated(data)
    const { internalname, type, beverage, specialDietAccommodations, additionalInfo } = data.fields
    return (
        <div className="block-style" id={slug}>
            {/* <h3 className="content_h3" style={{marginTop: 0}}>{deCamelize(slug)}</h3> */}
            <div>
                <div className="flex-between-centered">
                    {internalname && <h3 style={{marginTop: 10, marginBottom: 20}} className="content_h3">{internalname}</h3>}
                    <EditDate date={date} />
                </div>
                {type && (
                    <div>
                        <b>Food</b>
                        <ul className="list-style" style={{marginTop: 10}}>
                            {type.map(v => <li key={v}>{v}</li>)}
                        </ul>
                    </div>
                )}
                {beverage && (
                    <div style={{marginTop: 20}}>
                        <b>Beverage</b>
                        <ul className="list-style" style={{marginTop: 10}}>
                            {beverage.map(v => <li key={v}>{v}</li>)}
                        </ul>
                    </div>
                )}
                {specialDietAccommodations && (
                    <div style={{marginTop: 20}}>
                        <b>Special Diet Accommodations</b>
                        <ul className="list-style" style={{marginTop: 10}}>
                            {specialDietAccommodations.map(v => <li key={v}>{v}</li>)}
                        </ul>
                    </div>
                )}
                {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
            </div>
        </div>
    )
}

export default Meals