import EditDate from "../components/EditDate"
import { deCamelize, getLastUpdated } from "../functions/utils"

const NewExtensions = ({ data, slug, disclaimer }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">{deCamelize(slug)}</h2>
                <EditDate date={date} />
            </div>
            {disclaimer && <i style={{display: 'block', marginBottom: 7}}>{disclaimer}</i>}
             <div>
                {data && data.map((product, i) => {
                    const { name, slug, } = product.fields
                    return (
                    <div className='block-style' key={i}>
                        {name && <h4 className="content_h4" style={{marginBottom: 0}}>
                            <a href={`/extensions/${slug}`}>{name}</a>
                        </h4>}
                    </div>
                    )
                })}
            </div>
        </article>
     )
}

export default NewExtensions