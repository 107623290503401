import { deCamelize  } from "../functions/utils"
import Excursions from "./Excursions"
import Meals from "./Meals"
import InclusionsModule from "./InclusionsModule"

const Inclusions = ({ data, slug }) => {
    // TODO types (a lot actually)
    return (
        <article id={slug}>
            <h3 className="content_h3">{deCamelize(slug)}</h3>
            {data.map((inc, i) => {
                if (!inc || !inc.fields) return
                const type = inc.sys.contentType.sys.id
                return (
                    <div key={i}>
                        {type === 'tours' && <Excursions data={inc} slug='excursions' />}
                        {type === 'meals' && <Meals data={inc} slug='meals' />}
                        {type === 'inclusions' && <InclusionsModule data={[inc]} />}
                    </div>
                )
            })}
        </article>
    )
}

export default Inclusions
