import { deCamelize, getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"

const ExpeditionGuide = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">{deCamelize(slug)}</h2>
                <EditDate date={date} />
            </div>
            {data.map((item, i) => {
                if (!item || !item.fields) return
                const { internalName, link } = item.fields
                return (
                    <div className="block-style" key={i}>
                        <b className="content_h3">{internalName}</b>
                        {link && (
                            <div className='d-flex-align-center' style={{marginTop: 20}}>
                                <b>Link:</b>
                                <a className='link-wrap' href={link} target='_blank'>{link}</a>
                            </div>
                        )}
                    </div>
                )
            })}
        </article>
    )
}

export default ExpeditionGuide
