import { getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"

const Marketing = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">Sales & Marketing</h2>
                <EditDate date={date} />
            </div>
            {data.map((item, i) => {
                if (!item || !item.fields) return
                const { salesMarketing, brochures, spreadsheetLink } = item.fields
                return (
                    <div className="block-style" key={i}>
                        <b className="content_h3">{salesMarketing}</b>
                        {brochures && (
                            <div className='d-flex-align-center' style={{marginTop: 20}}>
                                <b>Brochure(s):</b>
                                <a className='link-wrap' href={brochures} target='_blank'>{brochures}</a>
                            </div>
                        )}
                        {spreadsheetLink && (
                            <div className='d-flex-align-center' style={{marginTop: 20}}>
                                <b>{salesMarketing}:</b>
                                <a className='link-wrap' href={spreadsheetLink} target='_blank'>{spreadsheetLink}</a>
                            </div>
                        )}
                    </div>
                )
            })}
        </article>
    )
}

export default Marketing
