import EditDate from "../components/EditDate"
import { deCamelize, getLastUpdated } from "../functions/utils"
import FreeText from "./FreeText"

const ExpeditionGear = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h3 className="content_h2" style={{marginTop: 0}}>{deCamelize(slug)}</h3>
                <EditDate date={date} />
            </div>
            {data.map((el, i) => {
                if (!el || !el.fields) return
                const { internalTitle, gearHowtoGet, gearRequired, name, brand, link, additionalInfo } = el.fields
                return (
                    <div className="block-style" key={i}>
                        <b className="content_h3" style={{marginBottom: 20}}>{internalTitle}</b>
                        {gearHowtoGet && (
                            <div>
                                <b>Gear How to Get</b>
                                <ul className="list-style" style={{marginTop: 10}}>
                                    {gearHowtoGet.map(v => <li key={v}>{v}</li>)}
                                </ul>
                            </div>
                        )}
                        {gearRequired && (
                            <div style={{marginTop: 20}}>
                                <b>Gear Required</b>
                                <ul className="list-style" style={{marginTop: 10}}>
                                    {gearRequired.map(v => <li key={v}>{v}</li>)}
                                </ul>
                            </div>
                        )}
                        {name && (
                            <div style={{marginTop: 20}}>
                                <b>Name</b>
                                <p>{name}</p>
                            </div>
                        )}
                        {brand && (
                            <div style={{marginTop: 20}}>
                                <b>Brand</b>
                                <p>{brand}</p>
                            </div>
                        )}
                        {link && (
                            <div className='d-flex-align-center' style={{marginTop: 20}}>
                                <b>Link:</b>
                                <a className='link-wrap' href={link} target='_blank'>{link}</a>
                            </div>
                        )}
                        {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
                    </div>
                )
            })}
        </article>
    )
}

export default ExpeditionGear