import { useState, useEffect } from 'react'
import downArrow from '../src/assets/down-arrow.svg'
import { Link, useLocation } from 'react-router-dom'

const AccordionItem = ({data}) => {
  const { children, name, isOpen } = data
  return (
    <>
      {children ? <img className={`arrow_icon ${isOpen ? 'open': ''}`} src={downArrow} alt='' /> : null}
      {name}
    </>
  )
}

const Accordion = ({children, name, slug, open}) => {
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
      setOpen(open)
    }, [open])
  
    const { pathname } = useLocation()
    const isActiveSlug = pathname === '/' + slug
    return (
      <div className='accordion'>
        {
          !slug ? (
            <button className='accordion_title' onClick={() => setOpen(!isOpen)}>
              <AccordionItem data={{children, name, slug, isOpen}} />
            </button>
          ) : (
            <span className={`accordion_title ${isActiveSlug ? 'accordion_title_active' : ''}`}>
              <Link to={slug}>
                <AccordionItem data={{children, name, isOpen}} />
              </Link>
            </span>
          )
        }
        <div className={`accordion_content ${isOpen ? 'open': ''}`}>
          {children}
        </div>
      </div>
    )
  }

export default Accordion