import { useEffect, useState, useMemo } from "react"
import { deCamelize, fetchFromExpeditions, getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"

const ExtensionDetails = ({ data, slug }) => {
    const [ extensions, setExtensions ] = useState([])
    const updatedAt = useMemo(() => {
        if (extensions && extensions.length) {
            return getLastUpdated(null, extensions)
        }
    }, [extensions])

    const fetcher = async () => {
        const dataToFetch = data.map(item => {
            const itemUrl = item.sys.urn.split('/')
            return fetchFromExpeditions(itemUrl[itemUrl.length - 1], 'extension')
        })
        const results = await Promise.all(dataToFetch)
        const extensionsMapped = results.map(result => ({
            title: result.items[0].fields.title,
            image: { fields: result.items[0].fields },
            sys: result.items[0].sys,
            slug: result.items[0].fields.slug
        }))
        setExtensions(extensionsMapped)
    }

    useEffect(() => {
      fetcher()
    }, [])

    return (
        <article id={slug}>
            <h2 className="content_h2">{deCamelize(slug)}</h2>
            <EditDate date={updatedAt} />
            <ul className="extensions">
                {extensions.map((extension, i) => (
                    <li key={i} className='block-style highlights__item'>
                        <a
                            href={`https://www.expeditions.com/extensions/${extension.slug}`}
                            target="_blank"
                        >
                            {extension.image && extension.image.fields && (
                                <div className='highlights__item--img'>
                                    <img src={extension.image.fields.asset[0].src} alt={extension.image.fields.asset[0].name} />
                                </div>
                            )}
                            <h4 className="content_h4" style={{marginTop: 20, marginBottom: 0}}>{extension.title}</h4>
                        </a>
                    </li>
                ))}
            </ul>
        </article>
     )
}

export default ExtensionDetails