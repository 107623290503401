import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import Embed from "../components/Embed"
import { deCamelize, getIframeSrcFromString, getRichContentStringForEmbed } from "../functions/utils"

const ExtensionOperatingDates = ({ embed, text, slug }) => {
    const chartEmbedCode = embed && embed.fields && embed.fields.chartEmbed ? getRichContentStringForEmbed(embed.fields.chartEmbed) : null
    return (
        <article id='extensionOperatingDates' style={{marginTop: 40}}>
            <h2 className="content_h2">{deCamelize(slug)}</h2>
            <p className="d-flex-align-center">
                <b>View {deCamelize(slug)}:</b>
                <a className='link-wrap' href={getIframeSrcFromString(chartEmbedCode)} target="_blank">{getIframeSrcFromString(chartEmbedCode)}</a>
            </p>
            <Embed data={embed.fields.chartEmbed} />

            {text ? <div dangerouslySetInnerHTML={{__html: documentToHtmlString(text)}} /> : null}
        </article>
    )
}

export default ExtensionOperatingDates