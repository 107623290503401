
const EditDate = ({date}) => {
  if (!date) return null
  return (
    <div className="edit-date">
      Last updated: {date}
    </div>
  )
};

export default EditDate;