
const DefaultAsset = ({ data }) => {
    return (
        <div className="assets-container">
            {data.map((img, i) => {
                const { file, title } = img.fields
                return (
                    <div className="asset-wrap" key={i}>
                        <img src={file.url} alt={title} />
                    </div>
                )
            })}
        </div>
    )
}

export default DefaultAsset