import { getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';
import '@algolia/autocomplete-theme-classic';
import { Autocomplete } from './Autocomplete';
import { proccessAlgoliaHits } from '../functions/utils';

const searchClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)

const Search = ({notModal}) => {
    return (
        <Autocomplete
            openOnFocus={true}
            detachedMediaQuery={notModal ? undefined : ''}
            insights={true}
            getSources={() => [
                {
                sourceId: 'hits',
                getItems({ query }) {
                    if (query && query.length > 1) {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [{
                                indexName: process.env.ALGOLIA_INDEX,
                                query,
                                params: {
                                    hitsPerPage: 1000,
                                },
                            }],
                        })
                    } else {
                        return []
                    }
                },
                onActive({ item, setContext }) {
                    const matchContainer = document.querySelector('.aa-PreviewDescription__content')
                    const matchContainerClone = document.querySelector('.PreviewDescription__content--Clone')
                    if (matchContainer) {
                        if (matchContainerClone) matchContainerClone.remove()
                        matchContainer.style.display = '-webkit-box'
                    }
                    const hits = proccessAlgoliaHits(item)
                    setContext({ preview: hits[0], item })
                },
                getItemInputValue({ state }) {
                    if (state.query.length < 2 && state.activeItemId === null) {
                        state.context.preview = undefined
                    }
                    // TODO doesn't work when search on product
                },
                templates: {
                    item({ item, components }) {
                        const hits = proccessAlgoliaHits(item)
                        const onExtraHitOver = (hit) => {
                            const matchContainer = document.querySelector('.aa-PreviewDescription__content')
                            if (matchContainer) {
                                const matchContainerParent = matchContainer.parentNode
                                const cloneEl = matchContainerParent.querySelector('.PreviewDescription__content--Clone')
                                if (cloneEl) {
                                    cloneEl.innerHTML = hit.name.value.replace(/__aa-highlight__/g, '<mark>').replace(/__\/aa-highlight__/g, '</mark>')
                                } else {
                                    const matchContainerClone = matchContainer.cloneNode(true)
                                    matchContainerClone.className = 'PreviewDescription__content--Clone'
                                    matchContainerClone.innerHTML = hit.name.value.replace(/__aa-highlight__/g, '<mark>').replace(/__\/aa-highlight__/g, '</mark>')
                                    matchContainer.style.display = 'none'
                                    matchContainerParent.insertBefore(matchContainerClone, matchContainerParent.firstChild)
                                }
                            }
                        }
                        hits.sort((a, b) => {
                            const firstMatch = a.name.matchLevel === 'full' ? 0 : 1
                            const secondMatch = b.name.matchLevel === 'full' ? 0 : 1
                            if (firstMatch < secondMatch) return -1
                            if (firstMatch > secondMatch) return 1
                            return 0
                        })
                        // maybe sort tags to be on top or make them stand out
                        const folder = item.contentType === 'extension' ? 'extensions/' : ''
                        return (
                            hits.map((hit, i) => (
                                <a
                                    className="aa-ItemLink"
                                    href={folder + item.fields.slug + (hit.anchor ? `#${hit.anchor}` : '')}
                                    key={hit._highlightResult.name.value + i}
                                    onMouseEnter={hits.length > 1 ? () => onExtraHitOver(hit) : undefined}
                                >
                                    <div className="aa-ItemContent">
                                        <div className="aa-ItemContentBody">
                                            <div className="aa-ItemContentTitle">
                                                <components.Highlight hit={hit} attribute="name" />
                                            </div>
                                            {hit.breadcrumbs && <div className='breadcrumbs'>{hit.breadcrumbs.join(' > ')}</div>}
                                        </div>
                                    </div>
                                </a>
                            ))
                        );
                    },
                },
                },
            ]}
        />
    )
}

export default Search