import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;

const InteractiveMap = ({ lng, lat }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [isVisible, setIsVisible] = useState(false)
    const [wasVisible, setWasVisible] = useState(false)

    useEffect(() => {
        const onScroll = () => {
            const rect = mapContainer.current?.getBoundingClientRect()
            const isInview = rect && (rect.top >= 0) && (rect.bottom <= window.innerHeight)
            if (!wasVisible) {
                setIsVisible(isInview)
                if (isInview) setWasVisible(true)
            }
        }
        window.addEventListener("scroll", onScroll)
    
        return () => window.removeEventListener("scroll", onScroll)
      }, [])


    
    useEffect(() => {
        if (!isVisible) return
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: 14
        });
        new mapboxgl.Marker({color: '#006CB5'}).setLngLat([lng, lat]).addTo(map.current)
    })
 
    return (
        <div ref={mapContainer} style={{height: 300}} />
     )
}

export default InteractiveMap