import EditDate from "../components/EditDate"
import { deCamelize, getLastUpdated } from "../functions/utils"
import Location from "./Location"
import Excursions from "./Excursions"
import Meals from "./Meals"
import FreeText from "./FreeText"

const InclusionsModule = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <div className="block-style">
            {slug && <h3 className="content_h3">{deCamelize(slug)}</h3>}
            <EditDate date={date} />
            {data.map((info, i) => {
                if (!info || !info.fields) return
                const { internalName, includedArrangements, hospitality, tour, meals, additionalInfo } = info.fields
                return (
                    <div key={i}>
                        <b className="content_h3" style={{marginBottom: 15}}>{internalName}</b>
                        {includedArrangements && (
                            <ul className="list-style" style={{marginTop: 10, marginBottom: 15}}>
                                {includedArrangements.map(v => <li key={v}>{v}</li>)}
                            </ul>
                        )}
                        {hospitality && hospitality.map((item, i) => <Location key={i} data={item} />) }
                        {tour && tour.map((item, i) => <Excursions key={i} data={item} />) }
                        {meals && meals.map((item, i) => <Meals key={i} data={item} slug='meals' />) }
                        {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
                    </div>
                )
            })}
        </div>
    )
}

export default InclusionsModule