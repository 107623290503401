import { deCamelize, getLastUpdated } from "../functions/utils"
import EditDate from "../components/EditDate"
import FreeText from "./FreeText"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

const Excursions = ({ data, slug }) => {
    const { name, description, duration, includedItems, additionalInfo } = data.fields
    const date = getLastUpdated(data)
    return (
        <div className="block-style">
            <div className="flex-between-centered">
                <h3 className="content_h3">{slug ? deCamelize(slug) : name}</h3>
                <EditDate date={date} />
            </div>
            {description && (
                <div>
                    <b>Excursion Description:</b>
                    {description ? <div dangerouslySetInnerHTML={{__html: documentToHtmlString(description)}} /> : null}
                </div>
            )}
            {duration && (
                <p style={{marginTop: 20}}><b>Duration (in hours)</b>: {duration}</p>
            )} 
            {includedItems && (
                <div>
                    <b>Included Items</b>
                    <ul className="list-style" style={{marginTop: 10}}>
                        {includedItems.map(v => <li key={v}>{v}</li>)}
                    </ul>
                </div>
            )}
            {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
        </div>
    )
}

export default Excursions
