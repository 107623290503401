import EditDate from "../components/EditDate"
import { deCamelize, getLastUpdated } from "../functions/utils"
import FreeText from "./FreeText"

const Flights = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">{deCamelize(slug)}</h2>
                <EditDate date={date} />
            </div>
            {data.map((el, i) => {
                if (!el || !el.fields) return
                const { flightType, additionalInfo } = el.fields
                return (
                    <div key={i}>
                        {flightType && (
                            <div style={{marginTop: 20, marginBottom: 10}}>
                                <b>Flight Type</b>
                                <ul className="list-style" style={{marginTop: 10}}>
                                    {flightType.map(v => <li key={v}>{v}</li>)}
                                </ul>
                            </div>
                        )}
                        {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
                    </div>
                )
            })}
        </article>
    )
}

export default Flights