import { Link } from "react-router-dom"
import Search from "./Search"
import LogoutButton from "./LogoutButton"

const BaseLayout = ({children, hasHeader = true}) => {
   return (
    <div className='main-content'>
        <header className="header">
            {hasHeader && (
                <div className="header_content">
                    <Link className="header_link" to="/">Home</Link>
                    {/* <Link className="header_link" to="/">How to use</Link> */}
                    <div className="header_search_wrap">
                        <Search />
                    </div>
                </div>
            )}
            <LogoutButton className="header_link header_link_last" />
        </header>
        <main className='main'>
            {children}
        </main>
    </div>
   )
}

export default BaseLayout
