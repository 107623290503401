import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import EditDate from "../components/EditDate"
import { deCamelize, getLastUpdated } from "../functions/utils"
import ExpeditionGear from "./ExpeditionGear"
import FreeText from "./FreeText"
import InclusionsModule from "./InclusionsModule"

const ActivityOpportunity = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h2 className="content_h2">{deCamelize(slug)}</h2>
                <EditDate date={date} />
            </div>
            {data.map((info, i) => {
                if (!info || !info.fields) return
                const { internalName, ActivityOverview, divingProvider, providedGear, levelOfExperience, inclusions, files, additionalInfo } = info.fields
                return (
                    <div className="block-style" key={i}>
                        <b className="content_h3" style={{marginBottom: 15}}>{internalName}</b>
                        {ActivityOverview && (
                            <div>
                                <b>Activity Overview / Parameters</b>
                                {ActivityOverview ? <div dangerouslySetInnerHTML={{__html: documentToHtmlString(ActivityOverview)}} /> : null}
                            </div>
                        )}
                        {divingProvider && (
                            <p><b>Activity Provider:</b> {divingProvider}</p>
                        )}
                        {providedGear && <ExpeditionGear data={providedGear} slug='providedGear' />}
                        {levelOfExperience && (
                            <div style={{marginTop: 20}}>
                                <b>Required Level of Experience</b>
                                <p>{levelOfExperience}</p>
                            </div>
                        )}
                        {inclusions && <InclusionsModule data={inclusions} slug='inclusions' />}
                        {/* TOASK files */}
                        {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
                    </div>
                )
            })}
        </article>
    )
}

export default ActivityOpportunity